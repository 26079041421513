'use strict';

angular.module('kljDigitalLibraryApp')
    .config(["$stateProvider", function($stateProvider) {
        $stateProvider
            .state('index.order', {
                url: '/order/:subscription',
                templateUrl: 'app/order/order.html',
                controller: 'OrderCtrl'
            })

    }]);